import React, {Dispatch, SetStateAction, useContext} from "react"
import PDPTooltip from "../PDPTooltip"
import DurationAndDownpayment from "./DurationAndDownpayment"
import InfoBox from "../InfoBox"
import {useTranslation} from "next-i18next"
import {BikeType} from "../../../utility/Types"
import useDiscount from "../../../hooks/useDiscount"
import {findBikeMetaFieldByName, isOccasionBike} from "../../../utility/Helper"
import LayoutContext from "../../../context/LayoutContext"
import {customPlanLengthMap} from "../plan/customPlanLengthMap"
import CheckoutContext from "../../../context/CheckoutContext"
import getMainCategory from "../../filter/GetMainCategory"
import {categorySlugs} from "../../filter/config/Categories"
import ConditionAndMileage from "./ConditionAndMileage"
import ReusableConsultation from "../ResusableConsultation"
import {useRouter} from "next/router"
import useBadges from "../../../hooks/useBadges"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import AnalyticsContext from "../../../context/AnalyticsContext"
import WhatsAppButton from "../../navigation/WhatsAppButton"
import FAQ from "../../home/faq/FAQ"
import Typography from "../../reusable/Typography/Typography"
import {testDriveLink} from "../../../constants"

type Props = {
    bike: BikeType,
    addingToCart: boolean,
    isOpen: boolean,
    startShake: () => void,
    setIsOpen: Dispatch<SetStateAction<boolean>>,
    setIsSponsorMeModalOpen: Dispatch<SetStateAction<boolean>>
    setAskAQuestion:Dispatch<SetStateAction<boolean>>
    setActive: Dispatch<SetStateAction<string>>
    executeScroll: () => void
    questions: any[]
    setAdditionalInfoDrawer: Dispatch<SetStateAction<any>>
    selectedSize: any
    handleCheckoutBtnClick: (directPayment?:any) => void
	selectedVariation: any
}

function PaymentAndBookMeetingDesktopSection({bike, questions, handleCheckoutBtnClick, selectedSize, setAdditionalInfoDrawer, isOpen, executeScroll, setActive, startShake, addingToCart, setIsSponsorMeModalOpen, setIsOpen, setAskAQuestion}:Props) {
	const {isMobile} = useContext(LayoutContext)
	const isSecondHand = isOccasionBike(bike)
	const router = useRouter()
	const {hasTestDrive} = useBadges(bike)
	const {customInstanceDiscount, hasOrganizationDiscount} = useDiscount(bike)
	const mileage = Number(findBikeMetaFieldByName(bike, "mileage"))
	const {data: checkoutData} = useContext(CheckoutContext)
	const mainCategory = getMainCategory(bike?.categories)
	const isAccessory = mainCategory?.name.toLowerCase() === categorySlugs.accessories
	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = checkoutData?.planLength ? planLengthMap ? planLengthMap[checkoutData?.planLength] : checkoutData?.planLength : 48
	const {t} = useTranslation()
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div className="d-none d-md-block">
			{isSecondHand && <ConditionAndMileage isSecondHand={isSecondHand}
				mileage={mileage} />}
			<div className={"row mb-4 mt-4"}>
				<div className={"col-12"}>
					<div className="position-relative">
						<PDPTooltip isOpen={isOpen}
							setIsOpen={setIsOpen}
							desktop
							label="financing"/>
					</div>
					<DurationAndDownpayment
						startShake={startShake}
						addingToCart={addingToCart}
						selectedSize={selectedSize}
						hasOrganizationDiscount={hasOrganizationDiscount}
						product={bike}
						isMobile={isMobile}
						isSecondHand={isSecondHand}
						disabled={!selectedSize}
						isLoading={addingToCart}
						handleCheckoutBtnClick={handleCheckoutBtnClick}
						isAccessory={isAccessory}
						handleSizeClick={startShake}
						planLength={planLength}
						initialPrice={Number(bike.uvpPrice)}
						priceInputBesidesTitle
						customInstanceDiscount={hasOrganizationDiscount ? customInstanceDiscount : null}
					/>
				</div>
			</div>
			<FAQ mostAskedOnly
				isMiniFAQDesign/>
			<div>
				<InfoBox bike={bike}
					setAdditionalInfoDrawer={setAdditionalInfoDrawer} />
			</div>
			<div className="col-12 px-3 border-top pt-4">
				{hasTestDrive ? <ReusableConsultation imageWidth={24}
					imgSrc="/assets/icons/test-drive.svg"
					text={t("test-drive-cta")}
					description={t("test-drive-label")}
					handleClick={() => {
						reactGA?.event({
							categories: gaCategories.testDrive,
							action: gaEvents.testDriveCTA,
							label: `Test Drive for ${bike.name} clicked`,
							nonInteraction: false
						})
						return router.replace(testDriveLink)
					}}
					className="cursor-pointer mb-4" /> : null}
				<Typography variant="bodyXSmBold"
					className="mb-2 text-secondary"
					semanticTag="p">{t("need-help-to-make-choice?")}</Typography>
				<ReusableConsultation isBookAppointment
					className="cursor-pointer mb-2" />
				<WhatsAppButton withTextAndArrow/>
			</div>
			<div className={"col-12 mt-4"}>
				<div className={"border-top"}/>
			</div>
			<div id="next-description-section"/>
		</div>
	)
}

export default PaymentAndBookMeetingDesktopSection
