import React, {useContext, useEffect, useMemo, useState} from "react"
import Link from "next/link"
import Price from "./Price"
import TotalPrice from "./TotalPrice"
import Typography from "../../reusable/Typography/Typography"
import CollectionItemBadge from "./CollectionItemBadge"
// import CustomSlider from "../../imageSlides/CustomSlider"
import {isOccasionBike} from "../../../utility/Helper"
import ComparisonCheckboxButton from "./ComparisonCheckboxButton"
import Image from "next/image"
import DataContext from "../../../context/DataContext"
import {useTranslation} from "next-i18next"
import ProductColors from "./ProductColors"
import getMainCategory from "../../filter/GetMainCategory"
import SwissMadeBadge from "./SwissMadeBadge"
import useDiscount from "../../../hooks/useDiscount"
import {attributesToHideType, BikeType} from "../../../utility/Types"
import ComparisonAttributes from "./ComparisonAttributes"
import StockWarning from "./StockWarning"
import useHandleBookmark from "../../../hooks/useHandleBookmark"
import LayoutContext from "../../../context/LayoutContext"
import PlpExplanation from "./PlpExplanation"
import PlpAiAssistant from "./PlpAiAssistant"
import {ModalContext} from "../../../context/ModalContext"
import dynamic from "next/dynamic"
import {useRouter} from "next/router"
import {getOrganizationDataFromStrapi} from "../../../utility/strapi/helpers"
import {testDriveLink} from "../../../constants"

const CustomSlider = dynamic(() => import("../../imageSlides/CustomSlider"))

type BadgeProps = {
    isNew: boolean
    kilometers: boolean
    occasion: boolean
    isBikeOfTheMonth: boolean
    isInStock: boolean
    hasTestDrive: boolean
    isRentable: boolean
    isNonEBike: boolean
	saleOfTheWeek?: boolean
	isFeatured?: boolean
	isBrandWorldRecommended?: boolean
	isRecommended?: boolean
	isSwissMade?: boolean
    is2025: boolean
}

type Props = {
    productUrl: string
    selected: boolean
	soldOut: boolean
	isWishList?: boolean
	bike: BikeType
    badgeProps: BadgeProps
    handleMouseEnter: (bikeSlug) => void
    handleMouseLeave: React.MouseEventHandler<HTMLElement>
    handleTouchStart: (bikeSlug) => void
    handleTouchCancel?: () => void;
    handleClick?: React.MouseEventHandler<HTMLElement>
    isTouching?: boolean
    isRowForMobile?: boolean
    cardWidth?: string
    isTwoCardsView?: boolean
    withArrows?: boolean
    withDots?: boolean
    withOneImage?: boolean
	mainCategory?: string
	isCarouselElement?: boolean
    isAccessory?: boolean
    onScreenCallback?: any
	isLast?: boolean
	hideComparison?:boolean
	forSearchContent?: boolean
	showComparisonAttributes?: boolean
	showIsCurrentBikeLabel?: boolean
	attributesToHide?: attributesToHideType
	isGridTopLeftRounded?: boolean
	isGridTopRightRounded?: boolean
	isGridBottomRightRounded?: boolean
	isGridBottomLeftRounded?: boolean
	isIkeaDesign?: boolean
	isMiniPLP?: boolean
	isStory?:boolean
	articleClassName?:string
	borderRadius?: string
	isReferredByAi?:boolean
    hasBorder?: boolean
    priceFontSize?: number
    currentSlugWithCarousel?: string
}

const CollectionItemGridView = ({
	productUrl,
	selected,
	soldOut,
	bike,
	badgeProps,
	handleMouseEnter,
	handleMouseLeave,
	handleTouchStart,
	handleClick,
	isRowForMobile,
	cardWidth = "288px",
	isTwoCardsView,
	withDots,
	attributesToHide,
	showIsCurrentBikeLabel,
	withOneImage,
	isCarouselElement,
	showComparisonAttributes,
	mainCategory,
	isAccessory,
	forSearchContent,
	isGridTopRightRounded = false,
	isGridTopLeftRounded = false,
	isGridBottomRightRounded = false,
	isGridBottomLeftRounded = false,
	isIkeaDesign = false,
	isMiniPLP = false,
	articleClassName = "",
	isStory = false,
	borderRadius,
	hasBorder = true,
	priceFontSize,
	currentSlugWithCarousel,
	isTouching,
	handleTouchCancel
}: Props) => {
	const {t} = useTranslation("common")
	const {isBikeOfTheMonth, is2025, isNew, kilometers, occasion, isInStock, isNonEBike, saleOfTheWeek, hasTestDrive, isRentable, isFeatured, isSwissMade, isRecommended, isBrandWorldRecommended} = badgeProps
	const isSecondHand = isOccasionBike(bike)
	const [loading, setLoading] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [transform, setTransform] = useState("translateX(10%)")
	const [result, setResult] = useState("")
	const {comparisonProducts, userLifestyle} = useContext(DataContext)
	const {openDrawer, closeModal, setModalContent} = useContext(ModalContext)
	const {isMobile} = useContext(LayoutContext)
	const isActive = comparisonProducts.includes(bike?.slug)
	const totalPhysicalStock = bike?.totalPhysicalStock
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world")
	const {onSale, finalPriceWithoutDownPayment, organizationSlug, discount} = useDiscount(bike)
	const showPartnershipExclusiveText = Boolean(bike?.customInstanceDiscounts?.[organizationSlug]) || (organizationSlug && !bike?.discount && !isAccessory)
	const {pageProps} = useContext(DataContext)
	const organizations = pageProps?.strapiContent?.data?.organizations
	const organizationData = getOrganizationDataFromStrapi(organizations, organizationSlug)
	const {handleAddBookmark, handleDeleteBookmark, isBookmarked} = useHandleBookmark(bike)
	const hasBadge = onSale || isSecondHand || isFeatured || isBrandWorldRecommended || isBikeOfTheMonth || is2025 || isNew || kilometers || occasion || isInStock || isNonEBike || isSwissMade || hasTestDrive || isRentable
	let colorsToShow = bike?.colorData
	const currentColor = bike?.colorData?.find(item => !item.slug)
	let comparisonObjects = null
	if (attributesToHide) {
		colorsToShow = useMemo(() => attributesToHide ? bike?.colorData?.filter(color => Object?.keys(attributesToHide || {})?.every((key, index) => {
			return (!attributesToHide[key]?.colors?.includes(color?.color) && Number(key) !== bike.id) || Number(key) === bike.id
		})) || [] : bike?.colorData ? [...bike.colorData] : [], [bike.id, Object?.keys(attributesToHide || {})?.length, bike.slug])

		comparisonObjects = useMemo(() => {
			return Object.entries(attributesToHide)?.[0]?.[1]
		}, [Object?.keys(attributesToHide || {})?.length, bike.slug])
	}

	const borderRadiusStyles = isIkeaDesign ? {borderRadius: borderRadius ? borderRadius : 0} : {borderRadius: forSearchContent ? 16 : 24}
	const {colors, ...comparisonAttributesObject} = comparisonObjects && !showIsCurrentBikeLabel ? comparisonObjects : {colors: ""}
	const bikeDisplayName = bike.name

	const bikeImage = (
		<Image
			src={ bike?.images?.[0]?.src || bike?.image}
			alt={bikeDisplayName}
			width={isMobile ? 290 : showComparisonAttributes ? 242 : 239}
			height={isMobile ? 290 : showComparisonAttributes ? 242 : 239}
			objectFit="cover"
			layout="responsive"
			style={{borderRadius: 16}}
		/>
	)

	useEffect(() => {
		if (currentSlugWithCarousel === bike.slug) {
			const timer = setTimeout(() => {
				setTransform("translateX(0)")
			}, 200)

			return () => clearTimeout(timer)
		}

		setTransform("translateX(10%)")
	}, [currentSlugWithCarousel, bike.slug])
	return (
		<>
			<PlpExplanation isModalOpen={isModalOpen}
				bike={bike}
				closeModal={() => setIsModalOpen(false)}
				loading={loading}
				aiText={result} />
			{isRowForMobile ? <Link href={productUrl}
				passHref={true}>
				<a
					onClick={handleClick}
					style={{
						zIndex: selected || isActive ? 150 : (isFeatured || isBrandWorldRecommended) ? 100 : null,
						opacity: soldOut ? 0.5 : 1,
						transformOrigin: "center center",
						minHeight: showComparisonAttributes && !isAccessory ? "600px" : isTwoCardsView ? "auto" : 420,
						pointerEvents: soldOut ? "none" : "auto",
						backgroundColor: "#fff",
						...borderRadiusStyles,
						width: isTwoCardsView ? "100%" : cardWidth,
						minWidth: isTwoCardsView ? cardWidth : cardWidth ? cardWidth : "100%",
						cursor: "pointer"
					}}
					className={`link-reset card-item h-100 
                            ${isActive ? "active-card-item-border" : (isFeatured || isBrandWorldRecommended) ?
			"featured-card-item-border" : "normal-card-item-border"} 
                            ${isCarouselElement ? " card " : ""} 
                            ${isGridBottomLeftRounded ? "rounded-bottom-left" : ""} 
                            ${isGridBottomRightRounded ? "rounded-bottom-right" : ""} 
                            ${isGridTopLeftRounded ? "rounded-top-left" : ""} 
                            ${isGridTopRightRounded ? "rounded-top-right" : ""} mobile-rounded-ikea-design 
                            ${isTwoCardsView ? "p-1" : "p-2"}   
                            ${selected && !isIkeaDesign ? "card-shadow" : null}  ${articleClassName}`}
					onTouchStart={() => handleTouchStart(bike?.slug)}
					onTouchCancel={() => handleTouchCancel()}
				>
					<div className="p-0 col-12 d-flex align-items-center justify-content-center">
						<div style={{
							borderRadius: isTwoCardsView ? 32 : 16,
							backgroundColor: "#fff",
							position: "relative",
							minHeight: "160px",
							width: "100%",
							marginTop: "0rem"
						}}
						className="d-flex flex-column text-start position-relative">
							{isBookmarked ? <div style={{width: "40px"}}
								onClick={handleDeleteBookmark}> <img className="bookmark"
									src={"/assets/icons/Heart-gold.svg"}
									alt="Wish list icon" /></div> : <div style={{width: "40px"}}
								onClick={handleDeleteBookmark}><img onClick={handleAddBookmark}
									className="bookmark"
									src={"/assets/icons/Heart.svg"}
									alt="Wish list icon" /> </div>}
							<ComparisonCheckboxButton slug={bike.slug}/>
							{hasBadge && <div
								className={"ps-0 d-flex align-items-start position-absolute z-index"}
								style={{marginTop: forSearchContent ? -15 : isIkeaDesign ? "5px" : -20, marginLeft: 5}}>
								<CollectionItemBadge
									testDriveLink={hasTestDrive ? testDriveLink : null}
									offersTestDrive={hasTestDrive}
									isMobile={true}
									isBikeOfTheMonth={isBikeOfTheMonth}
									isNonEBike={isNonEBike}
									is2025={is2025}
									swissMade={isSwissMade && isIkeaDesign}
									isFeatured={isFeatured}
									isBrandWorldRecommended={isBrandWorldRecommended}
									isRentable={isRentable || false}
									isNew={isNew}
									inStock={bike.stock_status === "instock"}
									kilometers={kilometers}
									discount={discount}
									occasion={isSecondHand}
									onSale={onSale}
									soldOut={soldOut}
									saleOfTheWeek={saleOfTheWeek}
									isIconOnly
								/>
							</div>}
							<div style={{width: "100%", height: "100%", padding: isIkeaDesign ? "28px 8px 0px 8px" : "8px"}}
								className="mx-auto mb-2">
								{
									withOneImage || bike?.image || bike?.images?.length === 1 ?
										<div className="pb-2">
											{bikeImage}
										</div>	:
										currentSlugWithCarousel === bike?.slug && isTouching ? (
											<div style={{transition: "transform 0.4s ease-in-out", transform}}
												className="custom-slider-mobile">
												<CustomSlider
													key={bike?.slug}
													arrows={selected}
													productUrl={productUrl}
													slidesList={bike?.images}
													currentSlide={1}
													dots={withDots}
													trackSliding
													currentSlugWithCarousel={currentSlugWithCarousel}
												/>
											</div>
										) : (
											<div className="pb-2">
												{bikeImage}

												<ul className="ps-0 container custom-dots list-unstyled"
												>
													{bike?.images?.map((_, index) => (
														<li
															key={index}
															className={index === 0 ? "slick-active" : ""}
														/>
													))}
												</ul>
											</div>
										)
								}
								<StockWarning amount={totalPhysicalStock as number} />

							</div>
						</div>
					</div>
					<div className="d-flex flex-column px-2 text-start justify-content-center">
						<div className={"mb-2 "}>
							<div>
								{showIsCurrentBikeLabel ? <Typography variant={"bodySm" }
									semanticTag="div"
									className="mb-1"
									style={{fontSize: 12, width: "100%", lineHeight: "16px", color: "#6C7074"}}>
									{t("current-bike")}
								</Typography> : null}
								<Typography variant={isTwoCardsView ? "bodyXSmBold" : "bodyLgBlack"}
									semanticTag="h4"
									className="mb-1"
									style={{fontSize: "12px", lineHeight: "20px", width: "90%"}}>
									{bikeDisplayName}
								</Typography>
								<div
									className={` d-flex ${isTwoCardsView ? "flex-row mb-2" : "mb-4"} justify-content-between align-items-center`}>
									<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"}
										semanticTag="span"
									>
										{mainCategory}
									</Typography>
								</div>
							</div>
							<div>
								<Price price={Number(bike.uvpPrice)}
									onSale={onSale}
									isSecondHand={isSecondHand}
									secondHandPrice={bike.displayPrice}
									isRowForMobile
									discountedPrice={finalPriceWithoutDownPayment}
									priceFontSize={priceFontSize ? priceFontSize : isTwoCardsView ? 24 : 36} />
								<TotalPrice price={bike.uvpPrice}
									onSale={onSale}
									listView
									isSecondHand={isSecondHand}
									secondHandPrice={bike.displayPrice}
									discountedPrice={finalPriceWithoutDownPayment}
									priceVariant={isTwoCardsView ? "bodyXSm" : "bodyLg"} />
							</div>
							<div className="mt-3">
								<div className="d-flex justify-content-between align-items-center">
									<Typography variant="bodyXSm"
										semanticTag="p">
										{`${t(getMainCategory(bike?.categories)?.name || "")}  `}
									</Typography><div
										className={` d-flex ${isTwoCardsView ? "flex-column mb-2" : "mb-4"} justify-content-between align-items-center`}>
										<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"}
											semanticTag="span">
											{mainCategory}
										</Typography>
									</div>
								</div>
								<ProductColors colors={colorsToShow}
									currentColor={currentColor}/>
							</div>
						</div>
						{isBrandWorld || isAccessory || !(JSON.parse(process.env.NEXT_PUBLIC_AI_IS_ACTIVATED || "false")) ? null : <PlpAiAssistant isMobile={true}
							closeModal={closeModal}
							openDrawer={openDrawer}
							setModalContent={setModalContent}
							isSelected={selected}
							bike={bike}
							setIsModalOpen={setIsModalOpen}
							setLoading={setLoading}
							setResult={setResult}
							userLifestyle={userLifestyle} />}
						{showComparisonAttributes && !isAccessory ? <ComparisonAttributes attributesToCompare={comparisonAttributesObject}
							bike={bike} /> : null}
					</div>
				</a>
			</Link> :
				<Link href={productUrl}
					passHref={true}
					legacyBehavior>
					<a className={"link-reset h-100"}
						onClick={handleClick}>
						<article style={{
							background: isStory ? "#fff" : "",
							position: "relative",
							zIndex: selected || isActive ? 150 : (isFeatured || isBrandWorldRecommended) ? 100 : 1,
							opacity: soldOut ? 0.5 : 1,
							transition: "background-color 0.2s",
							transformOrigin: "center center",
							pointerEvents: soldOut ? "none" : "auto",
							...borderRadiusStyles,
							height: isMobile && isCarouselElement ? "392px" : isMiniPLP ? "422px" :
								showComparisonAttributes && !isAccessory ? "auto" : "452px",
							minHeight: isMiniPLP ? "422px" : showComparisonAttributes && !isAccessory ?
								"680px !important" : organizationSlug && showPartnershipExclusiveText &&
                            bike.colorData?.length ? 490 : bike.colorData?.length &&
                            !cardWidth.startsWith("1") ? 432 : cardWidth.startsWith("1") ? "auto" : 434,
							width: cardWidth,
							cursor: "pointer"
						}}
						className={`h-100 
                        ${isTwoCardsView ? "p-2 p-md-3" : showComparisonAttributes ? "py-2" : "p-2 p-md-3"}
                        ${hasBorder ? isActive ? "active-card-item-border" : (isFeatured || isBrandWorldRecommended) ?
			"featured-card-item-border" : "normal-card-item-border" : null}  
                            card-item  ${isGridBottomLeftRounded ? "rounded-bottom-left" : ""}  
                            ${isGridBottomRightRounded ? "rounded-bottom-right" : ""}  
                            ${isGridTopLeftRounded ? "rounded-top-left" : ""}  
                            ${isGridTopRightRounded ? "rounded-top-right" : ""} ${articleClassName}`}
						onMouseEnter={() => handleMouseEnter(bike.slug)}
						onMouseLeave={handleMouseLeave}
						>
							<div className="p-0 col-12 d-flex align-items-center justify-content-center"
							>
								{isSwissMade ? <SwissMadeBadge isIkeaDesign /> : null}
								<div style={{
									borderRadius: 16,
									backgroundColor: "#fff",
									position: "relative",
									maxHeight: "249px",
									width: "100%",
									marginTop: "1rem"

								}}
								className="d-flex flex-column text-start">
									{isBookmarked ? <img onClick={handleDeleteBookmark}
										className="bookmark"
										src={"/assets/icons/Heart-gold.svg"}
										alt="Wish list icon" /> : <img onClick={handleAddBookmark}
										className="bookmark"
										src={"/assets/icons/Heart.svg"}
										alt="Wish list icon" />}
									<div className={`d-none d-md-block ${isAccessory && "d-md-none"}`}>
										<div className="col-12"><ComparisonCheckboxButton slug={bike.slug}/></div>
									</div>
									{isAccessory || !(JSON.parse(process.env.NEXT_PUBLIC_AI_IS_ACTIVATED || "false")) ? null : <PlpAiAssistant closeModal={closeModal}
										openDrawer={openDrawer}
										setModalContent={setModalContent}
										isSelected={selected}
										bike={bike}
										setIsModalOpen={setIsModalOpen}
										setLoading={setLoading}
										setResult={setResult}
										userLifestyle={userLifestyle} />}
									{hasBadge && <div
										className={"position-absolute d-flex  align-items-start z-index"}>
										<CollectionItemBadge
											testDriveLink={hasTestDrive ? testDriveLink : null}
											offersTestDrive={hasTestDrive}
											isFeatured={isFeatured}
											isBrandWorldRecommended={isBrandWorldRecommended}
											isNonEBike={isNonEBike}
											isBikeOfTheMonth={isBikeOfTheMonth}
											is2025={is2025}
											isNew={isNew}
											isRentable={isRentable || false}
											inStock={bike.stock_status === "instock"}
											kilometers={kilometers}
											occasion={isSecondHand}
											discount={discount}
											onSale={onSale}
											soldOut={soldOut}
											saleOfTheWeek={saleOfTheWeek}
											itemsLeft={totalPhysicalStock as number}
										/>
									</div>}
									<div style={{width: "100%", overflow: "hidden", borderRadius: "1rem", padding: isIkeaDesign ? "0px" : "8px"}}
										className={`mx-auto ${withOneImage && "d-flex justify-content-center"}`}>
										{
											withOneImage || bike?.image || bike?.images?.length === 1 ?
												<Image
													src={bike?.image || bike?.images?.[0]?.src}
													alt={bikeDisplayName}
													width={isMobile ? 288 : showComparisonAttributes ? 242 : 239}
													height={isMobile ? 288 : showComparisonAttributes ? 242 : 239}
													objectFit="cover"
													style={{borderRadius: 16}}
												/> :
												currentSlugWithCarousel === bike.slug ?
													<CustomSlider arrows={selected}
														productUrl={productUrl}
														slidesList={bike.images}
													/> :
													bikeImage
										}
									</div>
								</div>
							</div>
							<div style={{minHeight: "155px", padding: showComparisonAttributes ? " 8px 16px" : "0px"}}
								className=" d-flex flex-column gap-1  text-start justify-content-center card-item-price-section">
								<div className="d-flex justify-content-start align-items-center gap-3 flex-wrap">
									{showIsCurrentBikeLabel ? <Typography variant={"bodySm" }
										semanticTag="div"
										className="mb-1"
										style={{fontSize: 12, width: "100%", lineHeight: "16px", color: "#6C7074"}}>
										{t("current-bike")}
									</Typography> : null}
									{isRecommended && !cardWidth.startsWith("1") ? <div style={{padding: "2px 8px", background: "#DEE2E6", borderRadius: "8px"}}
										className="d-flex recommendation-tooltip-tag justify-conten-start align-items-center gap-2"><Typography variant="bodySm"
											style={{fontSize: "12px", lineHeight: "16px"}}
											className="recommendation-tooltip-tagtext">{t("recommendation-for-you-tag")}</Typography><Image src="/assets/icons/sparkle.svg"
											width={12}
											height={12} /><Typography style={{fontSize: "12px", lineHeight: "16px"}}
											variant="bodySmBold">{ t("recommended-for-you")}</Typography></div> : null}

									{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug && showPartnershipExclusiveText && <div className="d-flex align-items-center gap-2">
										<img src={organizationData?.companyIcon}
											width={"auto"}
											height={18}/>
										<Typography style={{marginTop: -2}}
											variant={ "bodyXSm"}
											semanticTag="span">
											{t(organizationData?.listingItem?.text).replace("instance", organizationData?.companyName)}
										</Typography>
									</div>}</div>
								<Typography variant={isTwoCardsView ? "bodySmBold" : "bodyXSmBold"}
									semanticTag="div"
									className=""
									style={{fontSize: 14, width: "100%", lineHeight: "20px"}}>
									{bikeDisplayName}
								</Typography>
								<div className={`${isTwoCardsView ? "mb-3" : "mt-2"} `}>
									<Price price={Number(bike.uvpPrice)}
										onSale={onSale}
										discountedPrice={finalPriceWithoutDownPayment}
										isSecondHand={isSecondHand}
										secondHandPrice={bike.displayPrice}
										isRowForMobile
										priceFontSize={24}/>
									<TotalPrice price={bike.uvpPrice}
										onSale={onSale}
										listView
										isSecondHand={isSecondHand}
										secondHandPrice={bike.displayPrice}
										discountedPrice={finalPriceWithoutDownPayment}
										priceVariant={isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm"} />
								</div>
								<div style={{height: "100%"}}
									className="mt-2 d-flex justify-content-end align-items-start flex-column">
									<div className="d-flex justify-content-between align-items-center">
										<Typography variant="bodyXSm"
											semanticTag="p">
											{`${t(getMainCategory(bike?.categories)?.name || "")}  `}
										</Typography><div
											className={` d-flex ${isTwoCardsView ? "flex-column mb-2" : "mb-4"} justify-content-between align-items-center`}>
											<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"}
												semanticTag="span">
												{mainCategory}
											</Typography>
										</div>
									</div>
									<ProductColors colors={colorsToShow}
										currentColor={currentColor}/>
								</div>
							</div>
							{showComparisonAttributes && !isAccessory ? <ComparisonAttributes attributesToCompare={comparisonAttributesObject}
								bike={bike} /> : null}
						</article>
					</a>
				</Link>
			}
		</>
	)
}

export default CollectionItemGridView
