import {useTranslation} from "next-i18next"
import React, {useContext} from "react"
import LayoutContext from "../../../context/LayoutContext"
import Typography from "../../reusable/Typography/Typography"
import Image from "next/image"
import StockWarning from "./StockWarning"
import {useRouter} from "next/router"
import {formatPrice} from "../../../utility/Helper"

type Props = {
	soldOut?: boolean
	onSale?: boolean
	occasion?: boolean
	kilometers?: boolean
	isNew?: boolean
	is2022?: boolean
	is2023?: boolean
	isBikeOfTheMonth?: boolean
	inStock?: boolean
	outOfStock?: boolean
	onBackOrder?: boolean
	isNonEBike?: boolean
	isMobile?: boolean
	discountKey?: string
	saleOfTheWeek?: boolean
	swissMade?: boolean
	fontSizeXSm?: boolean,
	itemsLeft?: number
	isFeatured?: boolean
	isBrandWorldRecommended?: boolean
	recommended?: boolean
	discount?:number
	offersTestDrive?: boolean
	isRentable?: boolean
	testDriveLink?:string
    isIconOnly?: boolean
    isRow?: boolean
    bikeQuantityLeftLabel?: string,
	is2025?: boolean
}

const CollectionItemBadge = ({
	soldOut,
	isMobile,
	occasion,
	isNew,
	onSale,
	isBikeOfTheMonth,
	inStock,
	outOfStock,
	onBackOrder,
	isNonEBike,
	saleOfTheWeek,
	swissMade,
	itemsLeft,
	isFeatured,
	isBrandWorldRecommended,
	offersTestDrive,
	testDriveLink,
	isRentable,
	fontSizeXSm = true,
	discount,
	isIconOnly,
	bikeQuantityLeftLabel,
	isRow,
	is2025
}: Props) => {
	const {t} = useTranslation("common")
	const {isListView} = useContext(LayoutContext)
	const router = useRouter()
	const isBrandWorld = router.pathname.includes("/brand-world")

	return (
		<div className={`${isRow ? "d-flex align-items-center flex-wrap" : "flex-row flex-column"}`}
			style={{gap: 2}}>
			{/* Place Fast delivery */}
			{swissMade ?
				<span style={{
					borderRadius: "8px",
					transform: isMobile ? "translateX(0%)" : "translateX(10%)",
					padding: 0,
					marginBottom: "4px",
					color: "#212529"
				}}
				className={" "}>
					<Image src="/assets/icons/switzerland-icon.svg"
						width={12}
						height={12} />
					{isIconOnly ? null : <Typography style={{fontSize: "12px", color: "#212529", display: "block"}}
						variant="bodyXSmBold"
						semanticTag="span"
						className={"badge py-1"}
					>{t("swiss-made")}</Typography>} </span> : null
			}
			{
				saleOfTheWeek ? <>
					<Typography variant="bodyXSmBold"
						semanticTag="span"
						className={"badge py-1"}
						style={{
							marginBottom: "2px", marginTop: "2px",
							borderRadius: "8px",
							color: "#212529",
							backgroundColor: "#E61C45"
						}}>{t("week-sale")}</Typography>
				</> : null}
			<>
				{(isFeatured || isBrandWorldRecommended) ?
					<Typography variant="bodyXSmBold"
						semanticTag="span"
						className="badge"
						style={{transform: "translateY(5%)", marginBottom: "2px", marginTop: "2px",
							width: "fit-content", borderRadius: "8px", padding: "4px 8px", whiteSpace: "nowrap",
							verticalAlign: "baseline", backgroundColor: "#FFF2DB"}}
					>
						<div className="d-flex align-items-center justify-content-center gap-2 ">
							<Image src="/assets/icons/featured.svg"
								width={12}
								height={12} />
							{isIconOnly ? null : <span style={{fontSize: "12px", color: "#785108"}}>
								{t("we-recommend")}
							</span>}
						</div>
					</Typography> : null}
				{inStock ?
					<Typography variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
						semanticTag="span"
						className="badge border-radius-8"
						style={{
							backgroundColor: "#EBF9F2",
							marginBottom: "2px",
							marginTop: "2px",
							lineHeight: "16px",
							color: "#077940",
							borderRadius: "8px",
							padding: "4px 8px"}}>
						{t("instock")}
					</Typography> : null
				}
				{bikeQuantityLeftLabel ?
					<Typography
						variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
						semanticTag="span"
						className="badge border-radius-8"
						style={{
							backgroundColor: "#FAF0EB",
							lineHeight: "16px",
							color: "#C54809",
							borderRadius: "8px",
							padding: "4px 8px"
						}}
					>
						{bikeQuantityLeftLabel}
					</Typography> : null
				}
				{outOfStock ?
					<Typography
						variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
						semanticTag="span"
						className="badge border-radius-8"
						style={{
							backgroundColor: "rgb(248, 248, 248)",
							color: "rgb(33, 37, 41)",
							borderRadius: "8px",
							padding: "4px 8px"}}>
						{t("out-of-stock")}
					</Typography> : null
				}
				{onBackOrder ?
					<Typography
						variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
						semanticTag="span"
						className="badge border-radius-8"
						style={{
							backgroundColor: "#EBF9F2",
							color: "#077940",
							borderRadius: "8px",
							padding: "4px 8px"}}>
						{t("onbackorder-status")}
					</Typography> : null
				}
				{(saleOfTheWeek || isFeatured || isBrandWorldRecommended || inStock) && !isIconOnly ? <br /> : null}
				{onSale ?
					<Typography variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
						semanticTag="span"
						className="badge d-flex align-items-center justify-content-center gap-1"
						style={{width: "max-content", backgroundColor: "#FAF0EB", marginBottom: "2px", marginTop: "2px", lineHeight: "16px", color: "#E61C45", borderRadius: "8px", padding: "4px 8px"}}>
						{`% - ${formatPrice(discount)}`} {isMobile ? null : router.locale === "de" ? "Rabatt" : "de remise"}
					</Typography> : null
				}
				{offersTestDrive ?
					<Typography onClick={() => {
						if (testDriveLink) {
							router.replace(testDriveLink)
						}
					}}
					variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
					semanticTag="span"
					className="badge"
					style={{
						marginBottom: "2px",
						marginTop: "2px",
						backgroundColor: "#FFF1F2",
						color: "#AC0217",
						lineHeight: "16px",
						borderRadius: "8px",
						padding: "4px 8px",
						cursor: testDriveLink ? "pointer" : "normal"}}>
						<div className="d-flex align-items-center justify-content-center gap-2 " >
							<Image src="/assets/icons/testDrive.svg"
								height={16}
								width={16}
								alt="Test drive" />
							{isIconOnly && !isBrandWorld ? null : <span >{t("test-drive")}</span>}
						</div>
					</Typography> : null
				}
				{isRentable ?
					<Typography variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
						semanticTag="span"
						className="badge"
						style={{marginBottom: "2px", marginTop: "2px", backgroundColor: "#F3F2FF", color: "#5448C8", lineHeight: "16px", transform: "translateY(20%)", borderRadius: "8px", padding: "4px 8px"}}>
						<div className="d-flex align-items-center justify-content-center gap-2 " >
							<Image src="/assets/icons/ebikeRent.svg"
								height={16}
								width={16}
								alt="Rent Bike for 3 days" />
							{isIconOnly ? null : <span >{t("3-days-rental")}</span>}
						</div>
					</Typography> : null
				}
				{isNonEBike ?
					<Typography variant="bodyXSmBoldMdBodySmBold"
						semanticTag="span"
						className="badge"
						style={{marginBottom: "2px", marginTop: "2px", fontSize: "12px", lineHeight: "16px", backgroundColor: "#EBEEFB", color: "#072AC8", borderRadius: "8px", padding: "4px 8px"}}>{t("non-electric")}
					</Typography> : null
				}

				{soldOut ?
					<Typography variant={fontSizeXSm ? "bodyXSmBold" : "bodySmBold"}
						semanticTag="span"
						style={{borderRadius: "8px", padding: "4px 8px"}}
						className="badge bg-secondary">{t("Ausverkauft")}
					</Typography> : null
				}
				{occasion ?
					<Typography variant="bodySmBold"
						semanticTag="span"
						style={{backgroundColor: "#FBEEF8", marginBottom: "2px", marginTop: "2px", color: "#CA2CA7", borderRadius: "8px", fontSize: "12px", lineHeight: "16px", padding: "4px 8px"}}
						className="badge ">
						{/* {kilometers} */}
						{t("second-hand")}
					</Typography> :
					isNew ?
						<div className="col d-inline-block me-2">
							<Typography variant="bodySmBold"
								semanticTag="span"
								className={"badge"}
								style={{borderRadius: "8px", marginBottom: "2px", marginTop: "2px", backgroundColor: "#F4EBF8", color: "#7602AC", padding: "4px 8px"}}>
								{t("badge-new")}
							</Typography></div> :
						null}
				{is2025 ?
					<Typography variant="bodyXSmBoldMdBodySmBold"
						semanticTag="span"
						className="badge bg-primary text-dark text-uppercase "
						style={{borderRadius: "8px", marginBottom: "2px", marginTop: "2px", color: "#212529", padding: "4px 8px"}}>2025</Typography> :
					null
				}
				<StockWarning amount={itemsLeft as number} />
				{isListView ? null : isBikeOfTheMonth ? <Typography variant="bodySmBold"
					semanticTag="span"
					style={{backgroundColor: "#000", padding: "4px 8px", marginBottom: "2px", marginTop: "2px"}}
					className="badge text-white text-uppercase ">
					{t("bike-of-the-month")}
				</Typography> : null}
			</>

		</div>

	)
}

export default CollectionItemBadge
