import React, {useContext, useEffect, useRef, useState} from "react"
import {useTranslation} from "next-i18next"
import {getQueryParams, isOccasionBike} from "../../utility/Helper"
import getMainCategory from "../filter/GetMainCategory"
import {useRouter} from "next/router"
import CartContext from "../../context/CartContext"
import LayoutContext from "../../context/LayoutContext"
import SimilarBikes from "./SimilarBikes"
import SoldOut from "./SoldOut"
import PropTypes from "prop-types"
import AskAQuestion from "./AskAQuestion"
import FAQ from "../home/faq/FAQ"
import InfoBox from "./InfoBox"
import DeliveryAndReturnsSidebar from "./DeliveryAndReturnsSidebar"
import AnalyticsContext from "../../context/AnalyticsContext"
import AnimationComponent from "../reusable/components/AnimationComponent/AnimationComponent"
import {categorySlugs} from "../filter/config/Categories"
import useOnScreen from "../../hooks/OnScreen"
import CrossSellProducts from "./CrossSellProducts"
import {ecommerceEvents} from "../../config/googleAnalytics/events"
import slugify from "slugify"
import ComparisonSection from "./ComparisonSection"
import {placeholders} from "../story/Story"
import Stories from "../story/Stories"
import ServiceCenters from "../home/serviceCenters/ServiceCenters"
import ProductInfo from "./ProductInfo"
import ComparisonPLPFixedComponent from "../bikesCollection/ComparisonPLPFixedComponent"
import LoadingOverlay from "../routeTransition/LoadingOverlay"
import FindPerfectBike from "../home/FindPerfectBike/FindPerfectBike"
import SponsorMeModal from "./SponsorMeModal"
import useDiscount from "../../hooks/useDiscount"
import MiniPLP from "../home/miniPLP/MiniPLP"
import SimilarBikesModal from "./SimilarBikesModal"
import ToastMessage from "../reusable/ToastMessage"
import useInitializePDPSizes from "../../hooks/useInitializePDPSizes"
import useBadges from "../../hooks/useBadges"
import useCheckoutHandler from "../../hooks/useCheckoutHandler"
import usePdpUtilities from "../../hooks/usePdpUtilities"
import useGetAccordionContent from "../../hooks/useGetAccordionContent"
import ProductGalleryAndPaymentSection from "./components/ProductGalleryAndPaymentSection"
import ReusableConsultation from "./ResusableConsultation"
import NotificationsContext from "../../context/NotificationsContext"
import BikeContent from "../reusable/components/BikeContent/BikeContent"
import useGetRecentlyViewedBikes from "../../hooks/useGetRecentlyViewedBikes"
import StickyPdpNavigation from "./StickyPdpNavigation"
import WhatsAppButton from "../navigation/WhatsAppButton"
import useHandleNavbarScrollBehaviour from "../../hooks/useHandleNavbarScrollBehaviour"
import DataContext from "../../context/DataContext"
import Typography from "../reusable/Typography/Typography"
import UpsellModal from "./components/UpsellModal"
import {testDriveLink} from "../../constants"

export const StockStatus = {
	inStock: "instock",
	outOfStock: "outofstock",
	onBackOrder: "onbackorder"
}

type Props = {
	bike: any
	isSecondHand?: boolean
	questions: any[]
}

const BikeDetails: React.FC<Props> = ({questions, bike}) => {
	const isSecondHand = isOccasionBike(bike)
	const brand = bike?.attributes?.find(e => e.slug === "brand")?.terms?.[0].name
	const {t} = useTranslation(["common", "secondHandBikes"])
	// Contexts
	const {items} = useContext(CartContext)
	const {isMobile} = useContext(LayoutContext)
	const {recentlyViewedBikes} = useGetRecentlyViewedBikes()
	// states
	const [askAQuestion, setAskAQuestion] = useState(false)
	const [addingToCart, setAddingToCart] = useState(false)
	const [isGoBackLoading, setIsGoBackLoading] = useState(false)
	const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false)
	const [additionalInfoDrawer, setAdditionalInfoDrawer] = useState<boolean>(false)
	const [isSponsorMeModalOpen, setIsSponsorMeModalOpen] = useState<boolean | null>(false)
	const [active, setActive] = useState<string>("")
	const mainCategory = getMainCategory(bike?.categories)
	const isAccessory = mainCategory?.name.toLowerCase() === categorySlugs.accessories ||
    bike?.categories?.some(cat => cat?.slug === "zubehoer")
	const isCartCompatibility = items.length === 0 ? true :
		isSecondHand === (isSecondHand ? items?.filter(item => !isOccasionBike(item.product)).length < 1 :
			items?.filter(item => !isOccasionBike(item.product)).length < 1)
	// Refs
	const checkoutBtnRef = useRef<HTMLDivElement>()
	const topOfPageRef = useRef<HTMLDivElement>()
	const refToBikeDescription = useRef(null)

	// Hooks
	const {variations
		, soldOut
		, selectedVariation
		, setSelectedVariation
		, selectedSize
		, setSelectedSize} = useInitializePDPSizes(bike)
	const {finalPriceWithoutDownPayment
		, onSale, discount} = useDiscount(bike)
	const {hasTestDrive} = useBadges(bike)
	const {showToast,
		setShowToast,
		getCartItem,
		handleCheckoutBtnClick,
		currentCompatibilityBike} = useCheckoutHandler(bike,
		isCartCompatibility,
		finalPriceWithoutDownPayment,
		selectedVariation,
		discount,
		onSale,
		selectedSize,
		setShowUpsellModal)
	const {incrementPdpViews} = useContext(NotificationsContext)
	const {setNavigationObjects, deals} = useContext(DataContext)
	const {isLoading, isShaking, startShake} = usePdpUtilities(bike)
	const {accordionContent} = useGetAccordionContent(bike, isAccessory, isMobile, selectedVariation, questions, t)
	const {isScrollingDown} = useHandleNavbarScrollBehaviour(false)
	const checkoutBtnOnScreen = useOnScreen(checkoutBtnRef)
	const {reactGA} = useContext(AnalyticsContext)
	const router = useRouter()
	const {tab} = getQueryParams(router.asPath)
	const executeScroll = () => {
		setActive(slugify(t("customers_questions").toLowerCase()))
		refToBikeDescription.current.scrollIntoView()
	}

	useEffect(() => {
		reactGA?.gtag(ecommerceEvents.event, ecommerceEvents.viewItem, {
			currency: ecommerceEvents.CHF,
			value: Number(bike?.uvpPrice),
			items: [
				{
					item_id: bike?.id,
					item_name: bike?.name
				}
			]
		})
		incrementPdpViews(bike)
	}, [bike?.slug])

	const [activeLink, setActiveLink] = useState<"photos" |
	"Übersicht" |
	"Q&A" |
	"accessories" |
	"Ähnliche E-Bikes" |
	"test-drive" |
	"find-perfect-bike-title">("photos")

	const qAndARef = useRef()

	const isQandAOnScreen = useOnScreen(qAndARef)
	const isOnScreenOverviewMobile = useOnScreen(refToBikeDescription)
	useEffect(() => {
		if (isQandAOnScreen || isOnScreenOverviewMobile || checkoutBtnOnScreen) {
			setActiveLink(isQandAOnScreen ?
				"Q&A" :
				isOnScreenOverviewMobile && isMobile ?
					"Übersicht" : checkoutBtnOnScreen ? "test-drive" : null)
		}
	}, [isQandAOnScreen, isOnScreenOverviewMobile, checkoutBtnOnScreen])
	useEffect(() => {
		setNavigationObjects({
			isScrollingDown,
			startShake,
			selectedSize,
			addingToCart,
			handleCheckoutBtnClick,
			isCartCompatibility,
			setActiveLink,
			activeLink,
			bike
		})
	}, [selectedSize, activeLink, addingToCart, isCartCompatibility, bike.slug])

	return (
		<>
			<UpsellModal
				bike={bike}
				deals={deals}
				setAddingToCart={setAddingToCart}
				showUpsellModal={showUpsellModal}
				isCartCompatibility={isCartCompatibility}
				currentCompBike={currentCompatibilityBike}
				closeModal={() => setShowUpsellModal(false)}
			/>
			{isMobile && bike.status !== "draft" &&
				bike.stock_status !== "outofstock" ? <StickyPdpNavigation
					isScrollingDown={isScrollingDown}
					startShake={startShake}
					selectedSize={selectedSize}
					addingToCart={addingToCart}
					handleCheckoutBtnClick={handleCheckoutBtnClick}
					isCartCompatibility={isCartCompatibility}
					setActiveLink={setActiveLink}
					activeLink={activeLink}
					bike={bike} /> : null}
			<div style={{overflowX: "hidden"}}
				className={"position-relative"}>
				<SimilarBikesModal attributes={bike.attributes || []} />
				<div data-cypress-formatted-price="data-cypress-price-test-"
					className="d-none">
					{finalPriceWithoutDownPayment}
				</div>
				<ToastMessage duration={4000}
					state="danger"
					showToast={showToast}
					setShowToast={setShowToast}
					message={t("add-to-cart-failed-label")} />
				{(isLoading || isGoBackLoading) && <LoadingOverlay isVisible={isLoading || isGoBackLoading} />}
				<SponsorMeModal handleSizeClick={startShake}
					disabled={!selectedSize}
					cartItem={getCartItem}
					bike={bike}
					isModalOpen={isSponsorMeModalOpen}
					closeModal={() => setIsSponsorMeModalOpen(false)} />

				{bike.sku ? <div className="smartfit-onlinesizing"
					id="onlinesizer-target"/> : null}
				<div id="photos"
					ref={topOfPageRef}
					className={"container pt-4"}>
					<ProductGalleryAndPaymentSection
						setActiveLink={setActiveLink}
						bike={bike}
						accordionContent={accordionContent}
						active={active}
						addingToCart={addingToCart}
						brand={brand}
						executeScroll={executeScroll}
						handleCheckoutBtnClick={handleCheckoutBtnClick}
						isAccessory={isAccessory}
						isSecondHand={isSecondHand}
						isShaking={isShaking}
						getCartItem={getCartItem}
						isSponsorMeModalOpen={isSponsorMeModalOpen}
						questions={questions}
						selectedSize={selectedSize}
						selectedVariation={selectedVariation}
						setActive={setActive}
						setAdditionalInfoDrawer={setAdditionalInfoDrawer}
						setAskAQuestion={setAskAQuestion}
						setIsSponsorMeModalOpen={setIsSponsorMeModalOpen}
						setSelectedSize={setSelectedSize}
						setSelectedVariation={setSelectedVariation}
						soldOut={soldOut}
						startShake={startShake}
						tab={tab}
						variations={variations}
						setIsGoBackLoading={setIsGoBackLoading}
					/>
				</div>
				<div className="d-md-none d-block container mt-4 px-3"
					ref={checkoutBtnRef}>
					{hasTestDrive ? <div style={{position: "absolute", marginTop: "-4rem"}}
						id="test-drive" /> : null}
					{hasTestDrive ? <ReusableConsultation imageWidth={24}
						imgSrc="/assets/icons/test-drive.svg"
						text={t("test-drive-cta")}
						description={t("test-drive-label")}
						handleClick={() => router.replace(testDriveLink)}
						className="cursor-pointer mt-3 mb-4" /> : null}
					<Typography variant="bodyXSmBold"
						className="text-secondary"
						style={{marginBottom: 14}}>{t("do-you-have-a-question?")}</Typography>
					<ReusableConsultation isBookAppointment
						className="cursor-pointer mt-3 mb-2" />
					<div className="mb-4">
						<WhatsAppButton withTextAndArrow/>
					</div>
					<InfoBox bike={bike}
						setAdditionalInfoDrawer={setAdditionalInfoDrawer}/>
				</div>
				<div className={"pt-3 pt-md-2 pt-md-0 bg-white"}>
					<div className="d-md-none d-block"
						id="next-description-section"/>
					<div className="container">
						<div
							className="d-md-none">
							<div id="overview-mobile"
								style={{position: "absolute", marginTop: "-3rem"}} />
							<ProductInfo
								setActiveLink={setActiveLink}
								accordionContent={accordionContent}
								tab={tab}
								t={t}
								active={active}
								setActive={setActive}
								bike={bike}
								isAccessory={isAccessory} />
							<div style={{position: "absolute", marginTop: "-10rem"}}
								className="d-md-none"
								id="questionAndAnswers-mobile" />
						</div>
					</div>
				</div>
				{!isAccessory && <div className="pt-md-0">
					<CrossSellProducts bike={bike}
						sellProducts={bike.crossSellProducts}
						bikeName={bike.name}/>
				</div>}
				<SimilarBikes
					setActiveLink={setActiveLink}
					currentBike={bike}
					similarBikes={bike.similarBikes?.length > 0 ? [bike, ...bike.similarBikes] : []}
					isAccessory={isAccessory}
					hasComparisonSection={false}/>
				<div className="mt-3 pt-5 mt-md-0 pt-md-0">
					<FindPerfectBike/>
				</div>
				<div className="text-md-center mb-4 pb-4 my-md-5 py-md-5 ">
					<BikeContent
						bikes={recentlyViewedBikes}
						title={t("recently-viewed-products-title")}
						hasComparisonSection={false}
					/>
				</div>
				<ServiceCenters/>
				<div className="container mt-5 pt-md-4 mt-md-0  d-md-flex">
					<div className="pe-md-5 col-md-6">
						<ComparisonSection/>
					</div>
					<div className="ps-md-5 col-md-6">
						<Stories title={t("biker_stories_title")}
							stories={placeholders}/>
					</div>
				</div>
				<div className={"mb-3 mb-md-0"}>
					<FAQ/>
				</div>
				<MiniPLP />
				<div className={"d-none d-md-block position-fixed container bg-white px-0"}
					style={{borderRadius: 5, bottom: 0, left: 0, right: 0, zIndex: 20000}}>
					<ComparisonPLPFixedComponent sticky={false}
						classes={"mt-0"}/>
				</div>
				{bike.stock_status === "soldout" || soldOut ? <SoldOut upsellsList={bike.similarBikes} /> : null}
				<AnimationComponent type="fade"
					inProp={askAQuestion}>
					<div className={"position-fixed top-0 start-0 bottom-0 end-0"}
						style={{
							backgroundColor: "rgba(0,0,0,0.5)",
							zIndex: 999999
						}}
						onClick={() => {
							setAskAQuestion(false)
						}}>
						<AskAQuestion setAskAQuestion={setAskAQuestion}/>
					</div>
				</AnimationComponent>
				<AnimationComponent type="fade"
					inProp={additionalInfoDrawer}>
					<DeliveryAndReturnsSidebar onClose={() => setAdditionalInfoDrawer(false)}/>
				</AnimationComponent>
			</div>
		</>
	)
}

BikeDetails.propTypes = {
	bike: PropTypes.any.isRequired
}

export default BikeDetails
